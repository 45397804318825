import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
  createTheme, ThemeProvider,
} from '@mui/material';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap icons CSS
import DownloadBrochureDialog from './DownloadBrochureDialog';

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form Data:', formData);
    // Clear the form fields
    setFormData({
      firstName: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  const theme = createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: false,
        },
        styleOverrides: {
          root: {
            
          
            
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
    <div style={{ position: 'fixed', width: '15%' }}>
    <style>
        {`
          @media (max-width: 767px) {
            .enquiry-card {
              display: none;
            }
            .download-dialog {
              position: fixed;
              bottom: 20px;
              right: 20px;

            }
          }
        `}
      </style>
      <Card
        className="enquiry-card"
        style={{
          backgroundColor: '#f0f0f0',
          boxShadow: '0px 0px 5px #ccc',
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div">
            Enquiry Form
          </Typography>
          <Divider sx={{ marginBottom: '10px', marginTop: '10px' }} />
          <form onSubmit={handleSubmit}>
            <TextField
              variant="standard"
              fullWidth
              margin="normal"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Enter your full name"
              required
            />
            <TextField
              variant="standard"
              fullWidth
              margin="normal"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email address"
              required
            />
            <TextField
              variant="standard"
              fullWidth
              margin="normal"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
              required
            />
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Enter your message"
              required
            />
            <Divider sx={{ marginBottom: '10px', marginTop: '10px' }} />
            <Button type="submit" variant="outlined" color='error' startIcon={<i class="bi bi-send"></i>}>
              {/* Use Bootstrap's send icon */}
               Send
            </Button>
          </form>
        </CardContent>
      </Card>
      <div className="download-dialog">
        <DownloadBrochureDialog />
      </div>
    </div>
    </ThemeProvider>
  );
};

export default EnquiryForm;
