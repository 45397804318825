import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';
import './BlogCard.css'; // Import the CSS file

const BlogCard = ({ title, content, author, date }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box className="blog-card">
        <Typography className="blog-title" variant="h5" component="div" align="center">
          {title}
        </Typography>
        <Typography className="blog-content" variant="body2" color="text.secondary" align="center">
          {content}
        </Typography>
        <Typography className="blog-info" variant="subtitle2" color="text.secondary" align="center">
          Author: {author} | Date: {date}
        </Typography>
        <Button className="read-more-button" size="small" align="center">
          Read More
        </Button>
      </Box>
    </motion.div>
  );
};

export default BlogCard;
