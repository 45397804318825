import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BlogCard from './BlogCard';
import Pagination from '@mui/material/Pagination';

const blogData = [
  {
    id: 1,
    title: 'Sample Blog 1',
    content: 'This is the content of sample blog 1.',
    author: 'John Doe',
    date: '2023-09-09',
  },
  {
    id: 2,
    title: 'Sample Blog 2',
    content: 'This is the content of sample blog 2.',
    author: 'Jane Smith',
    date: '2023-09-10',
  },
  {
    id: 1,
    title: 'Sample Blog 1',
    content: 'This is the content of sample blog 1.',
    author: 'John Doe',
    date: '2023-09-09',
  },
  {
    id: 2,
    title: 'Sample Blog 2',
    content: 'This is the content of sample blog 2.',
    author: 'Jane Smith',
    date: '2023-09-10',
  },
  {
    id: 1,
    title: 'Sample Blog 1',
    content: 'This is the content of sample blog 1.',
    author: 'John Doe',
    date: '2023-09-09',
  },
  {
    id: 2,
    title: 'Sample Blog 2',
    content: 'This is the content of sample blog 2.',
    author: 'Jane Smith',
    date: '2023-09-10',
  },
  {
    id: 1,
    title: 'Sample Blog 1',
    content: 'This is the content of sample blog 1.',
    author: 'John Doe',
    date: '2023-09-09',
  },
  {
    id: 2,
    title: 'Sample Blog 2',
    content: 'This is the content of sample blog 2.',
    author: 'Jane Smith',
    date: '2023-09-10',
  },
  {
    id: 1,
    title: 'Sample Blog 1',
    content: 'This is the content of sample blog 1.',
    author: 'John Doe',
    date: '2023-09-09',
  },
  {
    id: 2,
    title: 'Sample Blog 2',
    content: 'This is the content of sample blog 2.',
    author: 'Jane Smith',
    date: '2023-09-10',
  },
  // Add more blog objects here
];

const itemsPerPage = 4; // Number of blog cards per page

const BlogList = () => {
  const [page, setPage] = useState(1);

  const handleChange = (event, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className="blog-list-container">
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Blogs
      </Typography>
      {blogData.slice(startIndex, endIndex).map((blog) => (
        <BlogCard
          key={blog.id}
          title={blog.title}
          content={blog.content}
          author={blog.author}
          date={blog.date}
        />
      ))}
      <Pagination
        count={Math.ceil(blogData.length / itemsPerPage)}
        page={page}
        onChange={handleChange}
        color="primary"
      />
    </div>
    </div>
  );
};

export default BlogList;
