import React from 'react';
import { motion } from 'framer-motion';
import { Typography, Box, Container } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';
import AboutAnimate from './animation_lna84f45.json';

const AboutUsComponent = () => {
  const companyInfo = {
    description:
      'Iconic Stone A Prominent Division of Imaginative Shapes Pvt Ltd Founded in 2023, Iconic Stone is dedicated to delivering exceptional real estate services. Our mission is to redefine the real estate experience by combining innovative solutions with an unwavering commitment to quality and customer satisfaction.',
  };



  const textMotionVariants = {
    initial: { opacity: 0.7, scale: 1 },
    whileHover: { opacity: 1, scale: 1.05 },
  };

  const textStyle = {
    variant: 'body1',
    paragraph: true,
    textAlign: 'justify',
  };

  const words = companyInfo.description.split(' ');

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px',
    '@media (min-width: 600px)': {
      flexDirection: 'row', // On screens wider than 600px, align components horizontally
    },
  };

  const playerStyle = {
    height: '300px', // Default height for smaller screens
    width: '300px', // Default width for smaller screens
    '@media (min-width: 600px)': {
      height: '400px', // On screens wider than 600px, increase height
      width: '500px', // On screens wider than 600px, increase width
    },
  };

  return (
    <Box sx={containerStyle}>
      <Player
        autoplay
        loop
        src={AboutAnimate}
        sx={playerStyle}
      />
      <Container maxWidth="lg">
        <Typography {...textStyle}>
          {words.map((word, index) => (
            <motion.span
              key={index}
              variants={textMotionVariants}
              initial="initial"
              whileHover="whileHover"
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              {index < 2 ? <strong>{word}</strong> : word}{' '}
            </motion.span>
          ))}
        </Typography>
      </Container>
    </Box>
  );
};

export default AboutUsComponent;