import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import '../Styles/Navbar.css';
import Logo from '../assets/logo.png';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';


const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDropdownToggle = event => {
    event.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdown = label => {
    setActiveDropdown(label);
    setIsDropdownOpen(true);
  };

  const closeDropdown = () => {
    setActiveDropdown(null);
    setIsDropdownOpen(false);
  };

  const handleLinkClick = () => {
    // Close the menu when a link is clicked
    setIsMenuOpen(false);
  };

  const navLinks = [
    { label: "Home", path: "/", exact: true },
    {
      label: "About Us",
      dropdown: [
        { label: "Company Profile", path: "/company-profile" },
        { label: "Vision & Mission", path: "/vision-mission" },
        { label: "Founder’s Profile", path: "/founder-profile" },
      ],
    },
    { label: "Our Projects", path: "/services" },
    { label: "Contact Us", path: "/contact" },
  ];

  return (
    <nav className={`navbar ${isMenuOpen ? 'active' : ''}`}>
      <div className="navbar-logo">
        <Link to="/">
          <img src={Logo} alt="Your Logo" />
        </Link>
      </div>
      <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
        {navLinks.map(nav => (
          <div
            key={nav.label}
            className={`navbar-link-container`}
            onMouseEnter={() => handleDropdown(nav.label)}
            onMouseLeave={closeDropdown}
          >
            {nav.dropdown ? (
              <>
                <Link
                  to={nav.path}
                  onClick={handleDropdownToggle}
                  className={`navbar-link dropdown-toggle`}
                >
                  {nav.label}
                </Link>
                {isDropdownOpen && activeDropdown === nav.label && (
                  <div className="dropdown-content">
                    {nav.dropdown.map((item, index) => (
                      <Link
                        key={index}
                        to={item.path}
                        className="dropdown-item"
                      >
                        {item.label}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <Link
              to={nav.path}
              end={nav.exact} // Use 'end' prop for exact matching
              className={`navbar-link`}
              onClick={handleLinkClick} // Close menu when link is clicked
            >
            
                {nav.label}
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="navbar-right">
        <div className="whatsapp-button">
          <div className="careers-button button-text whatsapp-number-button">
            <Link to="/careers" className="navbar-link">
            <BusinessCenterIcon style={{ color: '#985634' }} /> Careers
            </Link>
          </div>
          <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
          <span className="whatsapp-number-button">
            <a
              href="https://api.whatsapp.com/send?phone=919810282307"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp whatsapp-icon fa-shake" shake></i>
              <span className="button-text">(+91) 9810282307</span>
            </a>
          </span>
        </div>
        <input
          type="checkbox"
          id="menu_checkbox"
          checked={isMenuOpen}
          onChange={toggleMenu}
        />
        <label htmlFor="menu_checkbox">
          <div></div>
          <div></div>
          <div></div>
        </label>
      </div>
    </nav>
  );
};

export default Navbar;
