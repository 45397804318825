import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import 'bootstrap-icons/font/bootstrap-icons.css';

const theme = createTheme({
  palette: {
    secondary: {
      main: "#f44336",
    },
  },
});

const ContactNow = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    // Handle download logic here
    // You can use 'name', 'email', and 'phone' states to collect user data
    // For example, send a request to your server with this data
    // Reset the input states and close the dialog after handling the download
    setName("");
    setEmail("");
    setPhone("");
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <style>
          {`
            @media (max-width: 767px) {
              .contact-button {
                background-color: red;
                color: white;
              }
            }
          `}
        </style>

        <Button
          variant="contained"
          color="secondary"
          startIcon={<MailOutlineIcon />} // Change the icon to MailOutlineIcon
          onClick={handleClickOpen}
          className="contact-button" // Renamed the class for styling
          style={{ marginTop: "20px" }}
        >
          Contact Now
        </Button>

        <Dialog open={open} onClose={handleClose} maxWidth="xs">
          <Divider textAlign="left"><DialogTitle color={"red"}>Contact Now</DialogTitle></Divider>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              type="text"
              fullWidth
              variant="standard"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="new-password" // Prevent browser from saving information for autofill
            />

            <TextField
              margin="dense"
              id="email"
              type="email"
              fullWidth
              variant="standard"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="new-password" // Prevent browser from saving information for autofill
            />

            <TextField
              margin="dense"
              id="phone"
              type="tel"
              fullWidth
              variant="standard"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              autoComplete="new-password" // Prevent browser from saving information for autofill
            />
          </DialogContent>
          <DialogActions style={{display: "flex" , justifyContent:"center"}}>
            <Button type="submit" variant="outlined" color='error' startIcon={<i class="bi bi-send"></i>}>
               Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default ContactNow;
