import React from 'react';
import { Typography, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { motion } from 'framer-motion';
import shadab from '../../assets/shadab.jpg';

const FounderProfile = () => {
  const name = 'Shadab Ali';
  // const role = 'Co-Founder';
  const linkedinUrl = 'https://www.linkedin.com/in/shadab-ali-1675bab8/';
  const about =
    'With over 16 years of diverse professional experience, Shadab Ali is a seasoned expert in the fields of real estate, banking, and insurance. His journey began in the financial sector, where he honed his skills and developed a deep understanding of financial markets, risk management, and client relations during his time in banking and insurance. Over the past 9 years, Shadab Ali has made a significant impact in the real estate industry, leveraging his extensive knowledge to provide top-notch services to clients. As a co-founder of Iconic Stone, he is dedicated to redefining the real estate experience by focusing on delivering exceptional post-sale services.';
  const textStyle = {
    variant: 'body1',
    paragraph: true,
    textAlign:'justify'
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Card style={{ maxWidth: 'm', margin: '10px' }}>
        <CardMedia component="img" height="200" image={shadab} alt="Profile Image" style={{ objectFit: 'cover' }} />
        <CardContent style={{ textAlign: 'center' }}>
          <Typography variant="h5" component="div">
            {name}
          </Typography>
          {/* <Typography color="text.secondary" gutterBottom variant="subtitle1">
            {role}
          </Typography> */}
          <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
            <Divider>
              <LinkedInIcon color="primary" style={{ fontSize: 40 }} />
            </Divider>
          </a>
        </CardContent>
      </Card>

      <div style={{ margin: '10px', textAlign: 'center' }}>
        <Divider sx={{ marginBottom: '20px' }} />
        <Typography {...textStyle}>
          {about.split(' ').map((word, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0.7, scale: 1 }}
              whileHover={{ opacity: 1, scale: 1.05 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              {word}{' '}
            </motion.span>
          ))}
        </Typography>
      </div>
    </div>
  );
};

export default FounderProfile;
