import React, { useState } from 'react';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { Player } from '@lottiefiles/react-lottie-player';
import image from './animation_lmoq7ktf.json';

const ContactSection = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckVisible, setIsCheckVisible] = useState(false);
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        const formData = {
            fullName,
            phoneNumber,
            email,
            message,
        };

        try {
            // Send the form data to your webhook URL
            const response = await fetch('https://hook.eu1.make.com/wu1qvixkcex3laqm6i5mufrjqnftrv5n', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setIsCheckVisible(true);
                setFullName('');
                setPhoneNumber('');
                setEmail('');
                setMessage('');
            } else {
                console.error('Failed to submit the form.');
            }
        } catch (error) {
            console.error('An error occurred while submitting the form:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h4" gutterBottom align="center" sx={{ marginTop: '20px', fontFamily: '"Roboto-Regular", Helvetica', color:"#323334" }}>
                        Contact Us
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Enter your full name"
                            required
                            margin="normal" // Add margin below the field
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Enter your phone number (optional)"
                            margin="normal" // Add margin below the field
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                            margin="normal" // Add margin below the field
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Enter your message"
                            required
                            margin="normal" // Add margin below the field
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="error"
                            startIcon={<SendIcon />}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </Button>
                        {isCheckVisible}
                    </form>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Player
                        autoplay
                        loop
                        src={image}
                        speed={2.5}
                        style={{ width: '70%', height: 'auto' }}
                    />
                </Grid>
                <div className="horizontal-line"></div>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom align="center" sx={{ marginTop: '10px', fontFamily: '"Roboto-Regular", Helvetica', color:"#323334" }}>
                        WHERE TO FIND US
                    </Typography>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39562.19109939653!2d77.42849771179705!3d28.592080212072727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef3dae777eab%3A0x67981121a8de8a04!2sGolden%20I%20Noida%20Extension!5e0!3m2!1sen!2sin!4v1723226191265!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Maps - CRC The Flagship Location"
                    ></iframe>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ContactSection;
