import React from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Container,
  Paper,
  Box
} from '@mui/material';
import termsOfUseData from './termsOfUseData.json'; // Import the JSON data

const TermsOfUse = () => {
  const {
    welcomeMessage,
    useOfWebsite,
    contentOnWebsite,
    linksToOtherWebsites,
    intellectualProperty,
    disclaimerOfWarranties,
    limitationOfLiability,
    changesToTerms,
    termination,
    governingLaw,
    entireAgreement,
    severability,
    waiver,
    notices,
    contactInformation,
    additionalTermsAndConditions,
    finalNote
  } = termsOfUseData;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }} color>
      <Paper variant="outlined" sx={{ padding: 3 }} elevation={0} background-color={"#f5f5f5"}>
        <Typography variant="h2" gutterBottom>
          Terms of Use
        </Typography>
        <Typography variant="body1" paragraph>
          {welcomeMessage}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Use of Website
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary={useOfWebsite.personalUse} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Prohibited Activities:"
              secondary={
                <List>
                  {useOfWebsite.prohibitedActivities.map((activity, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={activity} />
                    </ListItem>
                  ))}
                </List>
              }
            />
          </ListItem>
        </List>
        <Typography variant="h4" gutterBottom>
          Content on Website
        </Typography>
        <Typography variant="body1" paragraph>
          {contentOnWebsite}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Links to Other Websites
        </Typography>
        <Typography variant="body1" paragraph>
          {linksToOtherWebsites}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          {intellectualProperty}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Disclaimer of Warranties
        </Typography>
        <Typography variant="body1" paragraph>
          {disclaimerOfWarranties}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          {limitationOfLiability}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          {changesToTerms}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Termination
        </Typography>
        <Typography variant="body1" paragraph>
          {termination}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          {governingLaw}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Entire Agreement
        </Typography>
        <Typography variant="body1" paragraph>
          {entireAgreement}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Severability
        </Typography>
        <Typography variant="body1" paragraph>
          {severability}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Waiver
        </Typography>
        <Typography variant="body1" paragraph>
          {waiver}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Notices
        </Typography>
        <Typography variant="body1" paragraph>
          Iconic Stone:
          <br />
          Address: {notices.iconicStone.address}
          <br />
          Email: {notices.iconicStone.email}
          <br />
          User:
          <br />
          Name: {notices.user.name}
          <br />
          Address: {notices.user.address}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          {contactInformation}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Additional Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Property Listings: {additionalTermsAndConditions.propertyListings}
          <br />
          Buyer and Seller Services: {additionalTermsAndConditions.buyerAndSellerServices}
          <br />
          Privacy Policy: {additionalTermsAndConditions.privacyPolicy}
          <br />
          User Accounts: {additionalTermsAndConditions.userAccounts}
          <br />
          User Generated Content: {additionalTermsAndConditions.userGeneratedContent}
        </Typography>
      </Paper>
      <Box sx={{ mt: 3 }}>
        <Typography variant="body2" color="textSecondary" align="center">
          {finalNote}
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfUse;
