import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/HomePage';
import ProjectsPage from './components/ProjectsPage';
import Testimonials from './components/Testimonials';
import ContactSection from './components/Contacts';
import ApplyNow from './components/ApplyNow';
import Careers from './components/Careers';
import Bottom from './components/bottom';
import Footer from './components/Footer';
import Blogs from './components/Blogs/BlogList';
import FounderProfiles from './components/Abouts/FounderProfiles';
import TestimonialsManagement from './TestimonialsManagement';

// Import your project-specific components
import CRC from './components/ServicesDetails/CRC/CRC';
import CRCJoyous from './components/ServicesDetails/CRC Joyous/CRCJoyous';
import GodrejTropical from './components/ServicesDetails/Godrej Tropical Isle/GodrejTropical';
import LT from './components/ServicesDetails/L&T/L&T';
import Max from './components/ServicesDetails/Max/Max';
import M3M from './components/ServicesDetails/M3M The Cullinan/M3M';
import CompanyProfile from './components/Abouts/CompanyProfile';
import Mission from './components/Abouts/Mission';
import TermsOfUseComponent from './components/bottom/TermsOfUseComponent'
import PrivacyPolicy from './components/bottom/PrivacyPolicy'

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  const location = useLocation();
  const isProjectPage = location.pathname.startsWith('/project');

  return (
    <div id="home-section">
      {isProjectPage ? null : <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/services" element={<ProjectsPage />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/contact" element={<ContactSection />} />
        <Route path="/careers" element={<Careers />} />

        {/* Nested Routes for Project Pages */}
        <Route path="/project">
          <Route index element={<ProjectsPage />} />
          <Route path=":id" element={<CRC />} />
          <Route path="1" element={<CRCJoyous />} />
          <Route path="2" element={<GodrejTropical />} />
          <Route path="3" element={<LT />} />
          <Route path="4" element={<Max />} />
          <Route path="5" element={<M3M />} />
        </Route>

        <Route path="/applynow" element={<ApplyNow />} />
        <Route path="/company-profile" element={<CompanyProfile />} />
        <Route path="/vision-mission" element={<Mission />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/founder-profile" element={<FounderProfiles />} />
        <Route path="/admin" element={<TestimonialsManagement />} />
        <Route path="/terms-and-conditions" element={<TermsOfUseComponent />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        
      </Routes>
      <Bottom />
      <Footer />
    </div>
  );
};

export default App;
