import React from "react";
import { Typography, Grid  } from "@mui/material";

const OverviewGrid = () => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Address</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            Plot No. G9H5+9X, Sector 128, Noida, Uttar Pradesh 201304
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Project Size</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            10 acres
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Launch Date</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            August 2022
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Status</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Under Construction
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Price per Sqft</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              ₹15,000 onwards
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Possession by</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            May 2028
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Total Units</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            250
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Total Towers</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            3
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Project Type</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            Residential
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Property Type</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            Apartments
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Pincode</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            201304
            </Typography>
          </Grid>
        </Grid>
      );
};


export default OverviewGrid;
