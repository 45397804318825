import React from 'react';
import { Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faHome } from '@fortawesome/free-solid-svg-icons';
import OverviewGrid from './overviewGrid';

const Overview = () => {
    const listItemStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '10px',
        textAlign: 'justify'
    };

    const projectInfo = {
        overview: {
            title: "Overview",
            description: "Godrej Tropical Isle is a super-luxury residential project located in Sector 146, Noida Expressway, Noida. It is a premium integrated township that covers an area of approximately 12 acres and offers a unique blend of Earth, Water, Air, Fire, and Sky in perfect harmony. The project is being developed by Godrej Properties Limited, a prominent real estate developer in the country. It is expected to be completed by February 2030. Godrej Tropical Isle offers a variety of luxurious 3 and 4 BHK apartments with different sizes and configurations to choose from. Each apartment is thoughtfully planned with spacious areas and modern facilities for a smooth and luxurious experience.",
            keyFeatures: [
                "Located on the Noida Expressway, offering seamless connectivity to various parts of the city and NCR.",
                "Close to several commercial hubs, educational institutions, and healthcare facilities.",
                "Luxurious 3 and 4 BHK apartments with different sizes and configurations to choose from.",
                "Thoughtfully planned apartments with spacious areas and modern facilities.",
                "Wide range of amenities to cater to the needs of all residents.",
                "24/7 security for peace of mind."
            ]
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <Divider textAlign='left'>
                <Typography variant="h4" gutterBottom align="center" style={{ marginBottom: '15px' }}>
                    {projectInfo.overview.title}
                </Typography>
            </Divider>
            
            <Typography variant="subtitle2" paragraph style={{ textAlign: 'justify' }}>
                {projectInfo.overview.description}
            </Typography>
            <OverviewGrid/>
            <Typography variant="h5" gutterBottom style={{ marginTop: '20px', textAlign: 'justify' }}>
                Key Features
            </Typography>
            <List>
                {projectInfo.overview.keyFeatures.map((feature, index) => (
                    <ListItem key={index} style={listItemStyle}>
                        <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: '8px', marginTop: '3px' }} />
                        <ListItemText>
                            <Typography variant="subtitle2">
                                {feature}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default Overview;
