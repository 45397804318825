import React from "react";
import { Typography, Grid  } from "@mui/material";

const OverviewGrid = () => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Address</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            Plot No. GH, 01 A, near Noida-Greater Noida Expressway, Sector 146, Noida, Uttar Pradesh 201310
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Project Size</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            12 acres
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Launch Date</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            June 2023
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Status</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Under Construction
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Price per Sqft</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            Call for price
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Possession by</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            December 2027
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Total Units</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            826
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Total Towers</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            3
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Project Type</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            Residential
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Property Type</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            Apartments
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Pincode</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            201310
            </Typography>
          </Grid>
        </Grid>
      );
};


export default OverviewGrid;
