import React, { useState } from 'react';

const SnakeGame = () => {
  const [snake, setSnake] = useState([[10, 10], [9, 10], [8, 10]]);
  const [food, setFood] = useState([5, 5]);
  const [direction, setDirection] = useState('right');

  const moveSnake = () => {
    const newSnake = [...snake];
    const snakeHead = newSnake[0];

    switch (direction) {
      case 'right':
        snakeHead[0] += 1;
        break;
      case 'left':
        snakeHead[0] -= 1;
        break;
      case 'up':
        snakeHead[1] -= 1;
        break;
      case 'down':
        snakeHead[1] += 1;
        break;
      default:
        break;
    }

    newSnake.unshift(snakeHead);
    newSnake.pop();

    setSnake(newSnake);
  };

  const drawSnake = (canvas) => {
    const ctx = canvas.getContext('2d');

    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = 'green';
    snake.forEach((segment) => {
      ctx.fillRect(segment[0] * 10, segment[1] * 10, 10, 10);
    });

    ctx.fillStyle = 'red';
    ctx.fillRect(food[0] * 10, food[1] * 10, 10, 10);
  };

  return (
    <canvas
      width={200}
      height={200}
      ref={(canvas) => drawSnake(canvas)}
    />
  );
};

export default SnakeGame;
