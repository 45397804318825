import React from 'react';
import { Grid, Container, Typography,Divider } from '@mui/material';
import FounderProfile from './FounderProfile';
import FounderProfile2 from './FounderProfile2';

const FounderProfiles = () => {
  return (
    <div style={{ margin: '20px' }}>
      <Container maxWidth="xl">
        <Typography variant="h4" align="center" gutterBottom>
          Founder's
        </Typography>
        <Divider sx={{ marginBottom: '20px' }} />
        <Grid  spacing={0}>
          <Grid item xs={12} sm={6}>
            <FounderProfile />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FounderProfile2 />
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default FounderProfiles;
