import React from 'react';
import { Typography, Container } from '@mui/material';
import SnakeGame from './SnakeGame'

const ComingSoon = () => {
  const containerStyle = {
    textAlign: 'center',
    marginTop: '10rem', // You can adjust the margin as needed
  };

  const headingStyle = {
    fontSize: '3rem', // Adjust font size as needed
  };

  const bodyStyle = {
    fontSize: '1.2rem', // Adjust font size as needed
  };

  return (
    <Container style={containerStyle}>
      <Typography variant="h1" style={headingStyle}>
        Coming Soon!
      </Typography>
      <Typography variant="body1" style={bodyStyle}>
        We are working hard to bring you amazing content. Stay tuned!
      </Typography>
      <SnakeGame />
    </Container>
  );
};

export default ComingSoon;
