import React from 'react';
import { motion } from 'framer-motion';
import { Container, Typography } from '@mui/material';

const CompanyVision = () => {
  const visionText = `At Iconic Stone, we envision a future where real estate is more than just bricks and mortar; it's the cornerstone of thriving communities and boundless possibilities. Founded in 2023, our mission is to redefine the real estate industry by creating spaces that inspire, connect, and empower individuals and communities.`;

  const containerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 0.5 } },
  };

  const textContainerStyle = {
    marginLeft: '20px',
    marginRight: '20px',
  };

  const textStyle = {
    variant: 'body1',
    paragraph: true,
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="CompanyVision"
    >
      <Container maxWidth="lg" sx={{ borderLeft: 1 }}>
        <div sx={textContainerStyle}>
          <Typography {...textStyle}>
            {visionText.split(' ').map((word, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0.7, scale: 1 }}
                whileHover={{ opacity: 1, scale: 1.05 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                {word}{' '}
              </motion.span>
            ))}
          </Typography>
        </div>
      </Container>
    </motion.div>
  );
};

export default CompanyVision;
