import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faLeaf, faUsers, faHeart, faUniversalAccess, faCheckCircle, faUserFriends, faGlobe, faLaptop, faBuilding } from '@fortawesome/free-solid-svg-icons';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion'; // Import Framer Motion

const content = [
  {
    title: 'Innovate',
    description:
      'We relentlessly pursue innovative solutions that transform the way people experience and interact with real estate. From cutting-edge smart homes to sustainable urban developments, we lead the industry with pioneering ideas.',
    icon: <FontAwesomeIcon icon={faLightbulb} size="4x" style={{ color: '#a5acb9' }} />,
  },
  {
    title: 'Sustain',
    description:
      'We are committed to building a sustainable future, one project at a time. Our developments prioritize eco-friendly practices, renewable energy sources, and responsible resource management.',
    icon: <FontAwesomeIcon icon={faLeaf} size="4x" style={{ color: 'green' }} />,
  },
  {
    title: 'Connect',
    description:
      'We strive to create spaces that foster meaningful connections. Our communities are designed to bring people together, whether through shared amenities, co-working spaces, or vibrant public areas.',
    icon: <FontAwesomeIcon icon={faUsers} size="4x" style={{ color: '#ca853a' }} />,
  },
  {
    title: 'Empower',
    description:
      'We believe in empowering individuals and communities. Through affordable housing initiatives, community outreach programs, and education partnerships, we aim to uplift and support those in need.',
    icon: <FontAwesomeIcon icon={faHeart} size="4x" style={{ color: '#FF0000' }} />,
  },
  {
    title: 'Inclusivity',
    description:
      'We embrace diversity and inclusivity in all our endeavors. Our developments are designed to cater to a wide range of needs, ensuring that everyone has a place to call home.',
    icon: <FontAwesomeIcon icon={faUniversalAccess} size="4x" style={{ color: '#232056' }} />,
  },
  {
    title: 'Quality',
    description:
      'We never compromise on quality. Our commitment to excellence is evident in every detail, from architectural design to construction standards.',
    icon: <FontAwesomeIcon icon={faCheckCircle} size="4x" style={{ color: '#12db03' }} />,
  },
  {
    title: 'Customer-Centric',
    description:
      "We prioritize our customers' needs and satisfaction above all else. Every decision we make is guided by the goal of exceeding their expectations.",
    icon: <FontAwesomeIcon icon={faUserFriends} size="4x" style={{ color: '#3981a2' }} />,
  },
  {
    title: 'Global Reach',
    description:
      'While we are rooted in our local communities, we aspire to have a global impact. We aim to expand our footprint, sharing our vision for better real estate far and wide.',
    icon: <FontAwesomeIcon icon={faGlobe} size="4x" style={{ color: '#56585a' }} />,
  },
  {
    title: 'Technology-Driven',
    description:
      'We embrace the latest technology to enhance the real estate experience. From virtual property tours to seamless property management, we leverage technology for convenience and efficiency.',
    icon: <FontAwesomeIcon icon={faLaptop} size="4x" style={{ color: '#bd3a90' }} />,
  },
  {
    title: 'Community Partnerships',
    description:
      'We believe in the power of collaboration. We actively seek partnerships with local businesses, nonprofits, and government organizations to create holistic, vibrant neighborhoods.',
    icon: <FontAwesomeIcon icon={faBuilding} size="4x" style={{ color: '#323334' }} />,
  },
];

const descriptionVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.3, ease: 'easeInOut' } },
};

const ColumnsAndRowsWithIcons = () => {
  return (
    <div>
      <Grid container spacing={7}>
        {content.map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            {item.icon}
            <Typography variant="h5" gutterBottom>
              {item.title}
            </Typography>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={descriptionVariants}
            >
              {item.description.split(' ').map((word, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 0.7, scale: 0.9 }}
                  whileHover={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                >
                  {word}{' '}
                </motion.span>
              ))}
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ColumnsAndRowsWithIcons;
