import React from 'react';
import { Typography, List, ListItem, Container } from '@mui/material';


const PrivacyPolicy = () => {
  const data = {
    privacyPolicy: {
      
      personalInformationCollected: [
        "Name",
        "Email Address",
        "Phone Number",
        "Address",
        "IP Address",
        "Log Data (including browser information, pages viewed, access times)"
      ],
      purposesOfUse: [
        "To provide and maintain our services",
        "To notify you about changes to our services",
        "To respond to your inquiries, comments, or questions",
        "To improve our Site and services",
        "To comply with legal obligations"
      ],
      sharingInformation: "We may share your personal information with third parties only for the purposes listed above. We do not sell your personal information to third parties.",
      securityPrecautions: "We take reasonable precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.",
      cookiePolicy: "We use cookies to enhance user experience. Your web browser places cookies on your hard drive for record-keeping purposes and sometimes to track information about you.",
      userRights: "You have the right to access, correct, delete, or transfer your personal information that we collect. You may contact us at [Your Contact Email Address] to exercise these rights.",
      changesToPolicy: "We reserve the right to modify this Privacy Policy at any time. Changes and clarifications will take effect immediately upon their posting on the website.",
      contactInformation: "info@iconicstone.co.in"
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy for Iconic Stone
      </Typography>

      

      <Typography variant="h6" mt={2}>
        Personal Information We Collect
      </Typography>
      <List>
        {data.privacyPolicy.personalInformationCollected.map((info, index) => (
          <ListItem key={index}>{info}</ListItem>
        ))}
      </List>

      <Typography variant="h6" mt={2}>
        Purposes of Use
      </Typography>
      <List>
        {data.privacyPolicy.purposesOfUse.map((purpose, index) => (
          <ListItem key={index}>{purpose}</ListItem>
        ))}
      </List>

      <Typography variant="h6" mt={2}>
        Sharing Personal Information
      </Typography>
      <Typography>{data.privacyPolicy.sharingInformation}</Typography>

      <Typography variant="h6" mt={2}>
        Security
      </Typography>
      <Typography>{data.privacyPolicy.securityPrecautions}</Typography>

      <Typography variant="h6" mt={2}>
        Cookies
      </Typography>
      <Typography>{data.privacyPolicy.cookiePolicy}</Typography>

      <Typography variant="h6" mt={2}>
        Your Rights
      </Typography>
      <Typography>{data.privacyPolicy.userRights}</Typography>

      <Typography variant="h6" mt={2}>
        Changes to this Privacy Policy
      </Typography>
      <Typography>{data.privacyPolicy.changesToPolicy}</Typography>

      <Typography variant="h6" mt={2}>
        Contact Information
      </Typography>
      <Typography>{data.privacyPolicy.contactInformation}</Typography>
    </Container>
  );
};

export default PrivacyPolicy;
