import React from 'react';
import { Grid, Typography } from '@mui/material';

const footerStyle = {
  backgroundColor: '#333', // Example background color
  color: 'white',          // Example text color
  padding: '20px 0',
};

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="body2" align="center">
            &copy; {new Date().getFullYear()} Imaginative Shapes Pvt Ltd. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
