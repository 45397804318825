import React from 'react';
import { Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import OverviewGrid from './overviewGrid';
const Overview = () => {
    const listItemStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '10px',
        textAlign: 'justify'
    };
    const projectInfo = {
        overview: {
            title: "Overview",
            description: "CRC The Flagship, located in Sector 140A, Noida, India, is a sprawling mixed-use commercial complex spanning 11 acres. Boasting 1.1 million sq. ft. of space, it offers offices, retail outlets, and serviced apartments. As a comprehensive destination, it features top-notch amenities such as a mini cinema, infinity pool, security system, meditation area, event spaces, lift, conference rooms, and more. Conveniently situated near Noida Expressway, Dadri Road, and Sector 137 Metro Station, it's easily accessible and surrounded by residential and commercial hubs. The project, developed by CRC Group, will be completed in phases, with the first phase set for November 2027."
        },
        availableSpaces: {
            title: "Available Spaces",
            items: [
                {
                    title: "Office spaces",
                    description: "CRC The Flagship will offer a range of office spaces, from small co-working spaces to large corporate offices. The office spaces will be designed to be modern and efficient, with all the latest amenities."
                },
                {
                    title: "Retail spaces",
                    description: "CRC The Flagship will also offer a variety of retail spaces, including shops, restaurants, and cafes. The retail spaces will be located on the ground floor and lower ground floor of the complex."
                }
            ]
        }
    };
    return (
        <div style={{ margin: '20px' }}>
            <Divider textAlign='left'>
                <Typography variant="h4" gutterBottom align="center" style={{ marginBottom: '15px' }}>
                    {projectInfo.overview.title} 
                </Typography>
            </Divider>
            <Typography variant="subtitle2" paragraph style={{ textAlign: 'justify' }}>
                {projectInfo.overview.description}
            </Typography>
            <OverviewGrid/>
            <Typography variant="h5" gutterBottom style={{ marginTop: '20px', textAlign: 'justify' }}>
                {projectInfo.availableSpaces.title}
            </Typography>
            <List>
                {projectInfo.availableSpaces.items.map((item, index) => (
                    <ListItem key={index} style={listItemStyle}>
                        <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: '8px', marginTop: '3px' }} />
                        <ListItemText>
                        <Typography variant="subtitle2">
                    <strong>{item.title}:</strong> {item.description}
                </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
            
        </div>
    );
}

export default Overview;
