import React from "react";
import { Typography, Grid  } from "@mui/material";

const OverviewGrid = () => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Address</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            M3M Experia, Plot Number-1, Sector - 94, Noida
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Project Size</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            12.86 acres
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Launch Date</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            July 2023
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Status</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Under Construction
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Price per Sqft</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              ₹18,750 onwards
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Possession by</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            April 2028
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Total Units</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            374
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Total Towers</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              5
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Project Type</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            Residential
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Property Type</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            Apartment
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Pincode</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
            201309
            </Typography>
          </Grid>
        </Grid>
      );
};


export default OverviewGrid;
