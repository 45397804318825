import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BuildIcon from "@mui/icons-material/Build";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ApartmentIcon from '@mui/icons-material/Apartment';
import YourImage from "./logo.jpg";
import DownloadBrochureDialog from "../DownloadBrochureDialog";
import ContactNow from "../ContactNow";

const TopName = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box maxWidth="xl" style={{ position: "relative" }}>
      {!isMobile && (
        <img
          src={YourImage}
          alt="CRC Building"
          style={{
            float: "right",
            maxWidth: "300px",
            maxHeight: "300px",
            margin: "20px",
            boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.75)",
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          padding: 2,
        }}
      >
        <div style={{ flex: 1, marginRight: isMobile ? 0 : "20px" }}>
          <Typography variant="h4">
          M3M The Cullinan <FontAwesomeIcon icon={faHouse} color="red" />
          </Typography>
          <Typography variant="subtitle1">
            By M3M India Private Limited
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: 1,
            }}
          >
            <LocationOnIcon sx={{ color: "#606060", marginRight: 1 }} />
            <Typography variant="body2" sx={{ color: "#606060", marginRight: 1 }}>
            Sector 94, Noida
            </Typography>
            <BuildIcon sx={{ color: "#009681", marginRight: 1 }} />
            <Typography variant="body2" sx={{ color: "#009681", marginRight: 1 }}>
              Under Construction
            </Typography>
            <ScheduleIcon sx={{ marginRight: 1 }} />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              Possession by Dec, 2024
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ fontWeight: "bold", marginRight: 1, marginTop: 5 }}>
            ₹ 5.99 Cr - ₹ 12.12 Cr
            <Typography variant="body2" sx={{ color: "#606060" }}>
              <ApartmentIcon sx={{ color: "#009681", marginRight: 1 }} /> Residential (Apartment)
            </Typography>
          </Typography>
        </div>
        {isMobile && (
          <img
            src={YourImage}
            alt="CRC Building"
            style={{
              maxWidth: "100%",
              marginTop: "20px",
              boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.75)",
            }}
          />
        )}
      </Box>
      {!isMobile && (
        <Box
          style={{
            position: "absolute",
            bottom: 0,
            right: 300,
            padding: 20,
          }}
        >
          <ContactNow />
        </Box>
      )}
      {!isMobile && (
        <Box
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: 20,
          }}
        >
          <DownloadBrochureDialog />
        </Box>
      )}
    </Box>
  );
};

export default TopName;
