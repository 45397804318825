import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Divider, // Import the Divider component
} from '@mui/material';

import jobData from './jobData.json'; // Import the JSON data

const animationUrls = {
  'Portfolio Managers': 'https://lottie.host/751b06f8-ca8f-4e7c-ac2f-f17bd05589bd/qbBYAn4eo8.json',
  'Business Development Executive': 'https://lottie.host/e83ef581-8c92-468d-9cc3-6be27450bab9/5Gb9yeFusU.json',
  'Lead Sales': 'https://lottie.host/c11619ce-7b7d-433d-9f0a-1da205f9eb6d/LsHEPI7Qpz.json',
};

const Careers = () => {
  const [selectedCategory, setSelectedCategory] = useState('All'); // Default to 'All' category
  const [showCategories, setShowCategories] = useState(false); // Initially, hide the categories

  const [hoverStates, setHoverStates] = useState({}); // Store individual hover states

  // Toggle the visibility of categories
  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  const categories = ['All', 'Business Development Executive', 'Portfolio Managers', 'Lead Sales'];

  const handleApplyNowMouseEnter = (jobTitle) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [jobTitle]: true,
    }));
  };

  const handleApplyNowMouseLeave = (jobTitle) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [jobTitle]: false,
    }));
  };

  return (
    <Container maxWidth="lg">
      <Box textAlign="center" my={2}>
        <Typography variant="h4" gutterBottom>
          Careers
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box textAlign="left">
            <Button
              variant="contained"
              color="primary"
              onClick={toggleCategories}
              startIcon={<FontAwesomeIcon icon={faSliders} />}
            >
              {showCategories ? 'Hide Filters' : 'Show Filters'}
            </Button>
          </Box>
          {showCategories && (
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Filter by Category
                </Typography>
                {categories.map((category, index) => (
                  <Button
                    key={index}
                    variant={selectedCategory === category ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category}
                  </Button>
                ))}
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          {jobData
            .filter(job => selectedCategory === 'All' || job.jobTitle === selectedCategory)
            .map((job, index) => (
              <React.Fragment key={index}>
                <Card variant="outlined" style={{ display: 'flex' }}>
                  <CardContent style={{ flex: 1 }}>
                    <Typography variant="h5" component="div">
                      {job.jobTitle}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Experience: {job.experience}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Location: {job.location}
                    </Typography>
                    <Link to="/applynow">
                      <Button
                        variant={hoverStates[job.jobTitle] ? 'outlined' : 'contained'}
                        color="error"
                        onMouseEnter={() => handleApplyNowMouseEnter(job.jobTitle)}
                        onMouseLeave={() => handleApplyNowMouseLeave(job.jobTitle)}
                      >
                        Apply Now
                      </Button>
                    </Link>
                  </CardContent>
                  {job.jobTitle in animationUrls && (
                    <Box mt={1} display="flex" justifyContent="center" alignItems="center" alignSelf="flex-end" style={{ width: '50%' }}>
                      <Player
                        autoplay
                        loop
                        src={animationUrls[job.jobTitle]}
                        style={{ height: '50%', width: '50%' }}
                      />
                    </Box>
                  )}
                </Card>
                {index < jobData.length - 1 && <Divider sx={{ marginBottom: '20px' }} />} {/* Add a Divider between cards */}
              </React.Fragment>
            ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Careers;
