import React from 'react';
import TestimonialSlider from './TestimonialSlider'; // Make sure to adjust the path
import AnuragSeth from '../assets/AnuragSeth.jpeg'
import DeepakAdhar from '../assets/DeepakAdhar.jpeg'
import PankajPoptani from '../assets/PankajPoptani.jpeg'
const Testimonials = () => {
  const testimonialsData = [
    {
      id: 1,
      name: 'Anurag Seth',
      role: 'Director - Modi Mundipharma Pvt. Ltd. ',
      message: 'The best thing about Iconic Stone is their honesty and truthful guidance. They are very transparent and more than hundred percent reliable not only till the task is completed but ever thereafter. Money not only remains safe but grows faster following their advise. They have immense knowledge and experience of real estate world, so they are unquestionable in every way.',
      image: AnuragSeth,
    },
    {
      id: 2,
      name: 'Deepak Adhar',
      role: 'Commander Navy veteran',
      message: 'I have had the pleasure of knowing Irfan Habib from Iconic Stone for a couple of years now. Thanks to him, I was able to navigate my way in search of a property that was both an investment and an eventual settling opportunity for my twilight years. Irfan’s knowledge of the market, his rapport and leverage across builders and his keen financial acumen greatly aided the process. Most importantly, his inter personal skills and patience have been very endearing making the experience of finding a home so pleasurable.',
      image: DeepakAdhar,
    },
    {
      id: 3,
      name: 'Pankaj Poptani',
      role: 'Project Manager - Kyndryl India Pvt Ltd',
      message: 'I am incredibly pleased with services I received from ICONIC STONE for my home purchase at CRC Siblimis. Home buying process can be daunting, but their entire team was fantastic every step of the way. Beyond their expertise and professionalism, the team was amicable and kind throughout my home buying process. Today I am standing at more than 100% appreciation on CRC and all thanks to ICONIC STONE. I highly recommend them to anyone looking for top notch real estate services.',
      image: PankajPoptani,
    },
  ];

  return (
    <TestimonialSlider testimonials={testimonialsData} />
  );
};

export default Testimonials;
