import React from "react";
import "../Styles/style.css";
import vector from '../assets/img/vector.svg';
import vector2 from '../assets/img/vector-2.svg';
import vector3 from '../assets/img/vector-3.svg';
import vector4 from '../assets/img/vector-4.svg';
import vector5 from '../assets/img/vector-5.svg';
import vector6 from '../assets/img/vector-6.svg';
import vector7 from '../assets/img/vector-7.svg';
import vector8 from '../assets/img/vector-8.svg';
import vector9 from '../assets/img/vector-9.svg';
import vector10 from '../assets/img/vector-10.svg';
import vector1 from '../assets/img/vector-1.svg';

export const ImagePuzzle = () => {
  return (
    <div className="frame">
      <div className="div">
        <div className="overlap-group">
          <img className="vector" alt="Vector" src={vector} />
          <img className="img" alt="Vector" src={vector10} />
          <img className="vector-2" alt="Vector" src={vector9}  />
          <img className="vector-3" alt="Vector" src={vector8}  />
          <img className="vector-4" alt="Vector" src={vector7}  />
          <img className="vector-5" alt="Vector" src={vector6}  />
          <img className="vector-6" alt="Vector" src={vector5}  />
          <img className="vector-7" alt="Vector" src={vector4}  />
          <img className="vector-8" alt="Vector" src={vector3}  />
          <img className="vector-9" alt="Vector" src={vector2}  />
          <img className="vector-10" alt="Vector" src={vector1}  />
        </div>
      </div>
    </div>
  );
};

export default ImagePuzzle;