import React, { Suspense, lazy, memo } from 'react';
import { motion } from 'framer-motion';
import { Container, Typography, Paper, Divider } from '@mui/material';
import '../../Styles/TaglineComponent.css'; // Import the CSS file
import AboutUsComponent from './AboutUsComponent';

const LazyServicesComponent = lazy(() => import('./ServicesComponent'));
const LazyWhyChooseUsComponent = lazy(() => import('./WhyChooseUsComponent'));

const MemoizedAboutUsComponent = memo(AboutUsComponent);

const RealEstateCompanyProfile = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 0.5 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const containerStyle = {
    backgroundColor: '#f5f5f5',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.1)',
  };

  const textContainerStyle = {
    marginLeft: '20px',
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      exit="hidden"
      className="TaglineComponent"
    >
      <Container maxWidth="lg">
        <Paper elevation={3} sx={containerStyle}>
          <div sx={textContainerStyle}>
            <Typography variant="h5"><strong>About Us</strong></Typography>
            <MemoizedAboutUsComponent />
            <Divider sx={{ marginBottom: '20px' }} />
            <Typography variant="h5"><strong>Services</strong></Typography>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyServicesComponent />
            </Suspense>
            <Divider sx={{ marginBottom: '20px' }} />
            <Typography variant="h5" marginBottom={2}><strong>Why Choose Us</strong></Typography>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyWhyChooseUsComponent />
            </Suspense>
          </div>
        </Paper>
      </Container>
    </motion.div>
  );
};

export default RealEstateCompanyProfile;
