import React, { useState } from "react";
import {
  Typography,
  Divider,
  Card,
  CardContent,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import {
  SportsSoccer as SportsSoccerIcon,
  FitnessCenter as FitnessCenterIcon,
  LocalGasStation as LocalGasStationIcon,
  Elevator as ElevatorIcon,
  Security as SecurityIcon,
  Spa as SpaIcon,
  EventAvailable as EventAvailableIcon,
  Pool as PoolIcon,
  Bathtub as BathtubIcon,
  WaterDrop as WaterDropIcon,
  Nature as NatureIcon,
  Power as PowerIcon,
  Phone as PhoneIcon,
  Videocam as VideocamIcon,
  Fireplace as FireplaceIcon,
  DirectionsBike as DirectionsBikeIcon,
  BeachAccess as BeachAccessIcon,
} from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTree, faDumbbell, faTools, faShieldAlt, faTrophy } from '@fortawesome/free-solid-svg-icons';

const categoryIcons = {
  Environment: <FontAwesomeIcon icon={faTree} />,
  Activities: <FontAwesomeIcon icon={faDumbbell} />,
  Convenience: <FontAwesomeIcon icon={faTools} />,
  Safety: <FontAwesomeIcon icon={faShieldAlt} />,
  Sports: <FontAwesomeIcon icon={faTrophy} />,
};

const AmenitiesList = () => {
  const [activeTab, setActiveTab] = useState(0);

  const categories = {
    Environment: [
      { amenity: "Sewage treatment plant", icon: <LocalGasStationIcon /> },
      { amenity: "75 m wide green belt", icon: <NatureIcon /> },
      { amenity: "Rainwater harvesting", icon: <WaterDropIcon /> },
    ],
    Activities: [
      { amenity: "Spacious and modern clubhouse", icon: <FitnessCenterIcon /> },
      { amenity: "Cardroom", icon: <EventAvailableIcon /> },
      { amenity: "Jacuzzi", icon: <SpaIcon /> },
      { amenity: "Sauna and spa", icon: <BathtubIcon /> },
      { amenity: "Banquet hall", icon: <EventAvailableIcon /> },
      { amenity: "Large party hall", icon: <EventAvailableIcon /> },
    ],
    Convenience: [
      { amenity: "Pipeline gas supply", icon: <LocalGasStationIcon /> },
      { amenity: "Full power backup", icon: <PowerIcon /> },
      { amenity: "Modernized lifts", icon: <ElevatorIcon /> },
      { amenity: "24/7 water supply", icon: <WaterDropIcon /> },
    ],
    Safety: [
      { amenity: "Three-tier security", icon: <SecurityIcon /> },
      { amenity: "Intercom facility", icon: <PhoneIcon /> },
      { amenity: "CCTV surveillance", icon: <VideocamIcon /> },
      { amenity: "Firefighting operations", icon: <FireplaceIcon /> },
      { amenity: "24/7 security", icon: <SecurityIcon /> },
    ],
    Sports: [
      { amenity: "Squash court", icon: <SportsSoccerIcon /> },
      { amenity: "Skating ring", icon: <SportsSoccerIcon /> },
      {
        amenity: "Badminton court/basketball court",
        icon: <SportsSoccerIcon />,
      },
      { amenity: "Snooker/billiards/pool", icon: <PoolIcon /> },
      { amenity: "Cycling track", icon: <DirectionsBikeIcon /> },
      { amenity: "Kid's swimming pool", icon: <BeachAccessIcon /> },
      { amenity: "Yoga and meditation", icon: <FitnessCenterIcon /> },
      { amenity: "Table tennis court", icon: <SportsSoccerIcon /> },
      { amenity: "Swimming pool", icon: <PoolIcon /> },
      { amenity: "Central clubhouse", icon: <FitnessCenterIcon /> },
    ],
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Divider textAlign="left" >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          className="my-heading"
          style={{ marginTop: '15px' }}
        >
          Amenities
        </Typography>
      </Divider>

      <Tabs
  value={activeTab}
  onChange={handleChange}
  textAlign="left"
  variant="scrollable"
  scrollButtons="auto"
  sx={{ minHeight: 0, minWidth: 0, '& .MuiTabs-root': { minHeight: 0 } }}
>
  {Object.keys(categories).map((category, index) => (
    <Tab key={index} icon={categoryIcons[category]} label={category} sx={{ minWidth: 50 }} />
  ))}
</Tabs>


      {Object.keys(categories).map((category, index) => (
        <div
          key={category}
          style={{ display: activeTab === index ? "block" : "none" ,marginTop:"15px"}}
        >
          <Grid container spacing={1}>
            {categories[category].map((amenityInfo, amenityIndex) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={amenityIndex}>
                <Card
                  variant="outlined"
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#f5f5f5",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {amenityInfo.icon && (
                      <span style={{ marginBottom: "8px" }}>
                        {amenityInfo.icon}
                      </span>
                    )}
                    <Typography variant="body2" align="center">
                      {amenityInfo.amenity}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {index < Object.keys(categories).length - 1 && (
            <Divider variant="middle" sx={{ marginTop: 2 }} />
          )}
        </div>
      ))}
    </div>
  );
};

export default AmenitiesList;
