import React from "react";
import { Container, Grid, Divider } from "@mui/material";
import ImageSlider from "./ImageSlider";
import EnquiryForm from "../EnquiryForm";
import AmenitiesList from "./AmenitiesList";
import Overview from "./Overview";
import project1Image1 from "../../../assets/CRC-Flagship/1.jpg";
import project1Image2 from "../../../assets/CRC-Flagship/2.jpg";
import project1Image3 from "../../../assets/CRC-Flagship/3.jpg";
import TopName from './TopName';

const CRC = () => {
  const images = [project1Image1, project1Image2, project1Image3];

  return (
    <Container maxWidth="xl">
      <Divider sx={{ marginBottom: "20px" }} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <TopName />
        </Grid>
        <Grid item xs={12} sm={3}>
          <EnquiryForm />
        </Grid>
        <Grid item xs={12} sm={8} >
          <ImageSlider images={images} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Overview />
        </Grid>
        <Grid item xs={12} sm={5}>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <AmenitiesList />
        </Grid>
        <Grid item xs={12} sm={5}>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CRC;










// import React from "react";
// import { Container, Grid, Divider } from "@mui/material";
// import ImageSlider from "./ImageSlider";
// import EnquiryForm from "../EnquiryForm";
// import AmenitiesList from "./AmenitiesList";
// import Overview from "./Overview";
// import project1Image1 from "../../../assets/CRC-Flagship/1.jpg";
// import project1Image2 from "../../../assets/CRC-Flagship/2.jpg";
// import project1Image3 from "../../../assets/CRC-Flagship/3.jpg";
// import Properties from './Properties';
// import TopName from './TopName';

// const CRC = () => {
//   const images = [project1Image1, project1Image2, project1Image3];

//   return (
//     <Container maxWidth="xl">
//       <Divider sx={{ marginBottom: "20px" }} />
//       <Grid container spacing={4}>
//         <Grid item xs={12} sm={8}>
//           <TopName />
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <EnquiryForm />
//         </Grid>
//         <Grid item xs={6} sm={8} >
//           <ImageSlider images={images} />
//         </Grid>
//       </Grid>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={8}>
//           <Overview />
//           <Properties />
//         </Grid>
//         <Grid item xs={12} sm={5}>
//         </Grid>
//       </Grid>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={7}>
//           <AmenitiesList />
//         </Grid>
//         <Grid item xs={12} sm={5}>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default CRC;
