import React from 'react';
import { Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faHome } from '@fortawesome/free-solid-svg-icons';
import OverviewGrid from './overviewGrid';
const Overview = () => {
    const listItemStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '10px',
        textAlign: 'justify'
    };

    const projectInfo = {
        overview: {
            title: "Overview",
            description: "Max Estates 128 is a premium residential project by Max Estates Limited, located in Sector 128, Noida, Uttar Pradesh, India. Spread over an area of 10 acres, it offers a variety of apartments, from spacious 2BHKs to sprawling 4BHK villas."
        },
        keyFeatures: {
            title: "Key Features",
            features: [
                "Premium residential project with a variety of apartments to choose from.",
                "Spacious and well-designed apartments with all modern amenities.",
                "Host of amenities, including a swimming pool, reflexology park, spa, clubhouse, gym, and landscaped gardens.",
                "RERA-registered housing society (UPRERAPRJ446459) with 24/7 security for peace of mind.",
                "Close proximity to schools, hospitals, and shopping malls.",
                "Well-connected to major roads and highways for easy commute."
            ]
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <Divider textAlign='left'>
                <Typography variant="h4" gutterBottom align="center" style={{ marginBottom: '15px' }}>
                    {projectInfo.overview.title} 
                </Typography>
            </Divider>
            <Typography variant="subtitle2" paragraph style={{ textAlign: 'justify' }}>
                {projectInfo.overview.description}
            </Typography>
            <OverviewGrid/>
            <Typography variant="h5" gutterBottom style={{ marginTop: '20px', textAlign: 'justify' }}>
                {projectInfo.keyFeatures.title}
            </Typography>
            <List>
                {projectInfo.keyFeatures.features.map((feature, index) => (
                    <ListItem key={index} style={listItemStyle}>
                        <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: '8px', marginTop: '3px' }} />
                        <ListItemText>
                            <Typography variant="subtitle2">
                                {feature}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default Overview;
