import React from 'react';
import { motion } from 'framer-motion';
import '../Styles/TaglineComponent.css';

const TaglineComponent = () => {
  // const descriptionWords = [
  //   "Embodies", "our", "commitment", "to", "transforming", "real", "estate", "investments",
  //   "into", "powerful", "financial", "assets.", "We", "go", "beyond", "the", "conventional,",
  //   "ensuring", "that", "every", "property", "we", "offer", "is", "a", "testament", "to", "your",
  //   "financial", "aspirations", "and", "objectives.", "Our", "approach", "is", "all", "about",
  //   "empowerment,", "helping", "you", "choose", "properties", "that", "communicate", "value,",
  //   "maximize", "returns,", "and", "reflect", "your", "unique", "goals.", "With", "us,",
  //   "your", "investment", "is", "not", "just", "a", "transaction;", "it's", "a", "dynamic",
  //   "statement", "of", "financial", "growth", "and", "prosperity,", "confidently", "voiced",
  //   "through", "the", "properties", "we", "curate."
  // ];
  const descriptionWords = [
    "Our", "mission", "is", "to", "turn", "real", "estate", "investments", "into", "strong", "financial", "assets.",
    "We", "go", "above", "and", "beyond", "the", "usual,", "making", "sure", "that", "every", "property", "we",
    "offer", "reflects", "your", "financial", "goals.", "We", "empower", "you", "to", "choose", "properties",
    "that", "bring", "value,", "maximize", "returns,", "and", "align", "with", "your", "unique", "objectives.",
    "With", "us,", "your", "investment", "is", "not", "just", "a", "transaction;", "it's", "a", "clear", "sign",
    "of", "financial", "growth", "and", "prosperity,", "demonstrated", "through", "the", "properties", "we",
    "carefully", "select."
  ];
  

  return (
    <div className="TaglineComponent">
      <div className="tagline">
        <h2 className="tagline1">we make</h2>
        <h2 className="tagline2">your investment</h2>
        <h2 className="tagline3">speak</h2>
      </div>
      <div className="vertical-line"></div>
      <p className="description">
        {descriptionWords.map((word, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0.7, scale: 1 }}
            whileHover={{ opacity: 1, scale: 1.5 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            {word}{' '}
          </motion.span>
        ))}
      </p>
    </div>
  );
};

export default TaglineComponent;
