import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import Gallery from "react-image-gallery";
import PropTypes from 'prop-types';

const ImageGallerySlider = ({ images }) => {
  const galleryImages = images.map((imageUrl, index) => ({
    original: imageUrl,
    thumbnail: imageUrl,
    description: `Image ${index + 1}`,
  }));

  const galleryOptions = {
    items: galleryImages,
    autoPlay: true,
    slideInterval: 3000,
    infinite: true,
    thumbnailPosition: "right",
  };

  const containerStyle = {
    width: '100%',
    maxWidth: '1000px',
    margin: '0 auto',
    /* Add any other styles you want for larger screens */

    // Responsive styles for phones
    '@media (max-width: 767px)': {
      maxWidth: '100%', // Make the slider full width on smaller screens
      padding: '0 10px', // Add some padding on the sides for better spacing
    },
  };

  return (
    <div style={containerStyle}>
      <Gallery {...galleryOptions} />
    </div>
  );
};

ImageGallerySlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ImageGallerySlider;
