import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#f44336',
    },
  },
});

const DownloadBrochureDialog = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    // Handle download logic here
    // You can use 'name', 'email', and 'phone' states to collect user data
    // For example, send a request to your server with this data
    // Reset the input states and close the dialog after handling the download
    setName("");
    setEmail("");
    setPhone("");
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <style>
          {`
            @media (max-width: 767px) {
              .download-button {
                background-color: red;
                color: white;
              }
            }
          `}
        </style>

        <Button
          variant="outlined"
          color="secondary" // Set the button color using the theme
          startIcon={<CloudDownloadIcon />}
          onClick={handleClickOpen}
          className="download-button"
          style={{ marginTop: "20px"  }}
        >
          Download Brochure
        </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Download Brochure</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide your information to download the brochure.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            variant="standard"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="new-password" // Prevent browser from saving information for autofill
          />

          <TextField
            margin="dense"
            id="email"
            type="email"
            fullWidth
            variant="standard"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="new-password" // Prevent browser from saving information for autofill
          />

          <TextField
            margin="dense"
            id="phone"
            type="tel"
            fullWidth
            variant="standard"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            autoComplete="new-password" // Prevent browser from saving information for autofill
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDownload} style={{ color: "#f44336" }}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </ThemeProvider>
  );
};

export default DownloadBrochureDialog;
