import React, { useState } from "react";
import "../Styles/ContactSection.css";

const ContactSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckVisible, setIsCheckVisible] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const formData = {
      fullName,
      phoneNumber,
      email,
      message,
    };

    try {
      // Send the form data to your webhook URL
      const response = await fetch(
        "https://hook.eu1.make.com/wu1qvixkcex3laqm6i5mufrjqnftrv5n",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setIsCheckVisible(true);
        // Clear the form fields after a successful submission
        setFullName("");
        setPhoneNumber("");
        setEmail("");
        setMessage("");
      } else {
        console.error("Failed to submit the form.");
      }
    } catch (error) {
      console.error("An error occurred while submitting the form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="contact-section">
      <div className="content-container">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Phone Number - Optional"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
            <div className="form-group">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
                placeholder="Message"
                required
              />
            </div>
            <button type="submit" className="button" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </button>
            <div className={`loader ${isLoading ? "active" : ""}`}>
              <div className={`check ${isCheckVisible ? "active" : ""}`}>
                <span className="check-one"></span>
                <span className="check-two"></span>
              </div>
            </div>
          </form>
        </div>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39562.19109939653!2d77.42849771179705!3d28.592080212072727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef3dae777eab%3A0x67981121a8de8a04!2sGolden%20I%20Noida%20Extension!5e0!3m2!1sen!2sin!4v1723226191265!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps - CRC The Flagship Location"
          ></iframe>
          
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

//   old version -----------------------------------------------------------------------------------------------------

// import React, { useState } from 'react';
// import '../Styles/ContactSection.css';

// const ContactSection = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [isCheckVisible, setIsCheckVisible] = useState(false);
//   const [fullName, setFullName] = useState('');
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     setIsLoading(true);

//     setTimeout(() => {
//       setIsLoading(false);
//       setIsCheckVisible(true);
//       // Handle form submission logic...
//       // Clear the form fields after submission
//       setFullName('');
//       setPhoneNumber('');
//       setEmail('');
//       setMessage('');
//     }, 2000);
//   };

//   return (
//     // <div id="contact-us-section" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
//     <section className="contact-section">
//       <h2>Contact Us</h2>
//       <div className="content-container">
//         <div className="form-container">
//           <form onSubmit={handleSubmit}>
//             <div className="form-group">
//               <input
//                 type="text"
//                 value={fullName}
//                 onChange={(e) => setFullName(e.target.value)}
//                 placeholder="Full Name"
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <input
//                 type="tel"
//                 value={phoneNumber}
//                 onChange={(e) => setPhoneNumber(e.target.value)}
//                 placeholder="Phone Number - Optional"
//               // Remove the "required" attribute
//               />
//             </div>
//             <div className="form-group">
//               <input
//                 type="email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 placeholder="Email"
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <textarea
//                 value={message}
//                 onChange={(e) => setMessage(e.target.value)}
//                 rows="4"
//                 placeholder="Message"
//                 required
//               />
//             </div>
//             <button type="submit" className="button">
//               Submit
//             </button>
//             <div className={`loader ${isLoading ? 'active' : ''}`}>
//               <div className={`check ${isCheckVisible ? 'active' : ''}`}>
//                 <span className="check-one"></span>
//                 <span className="check-two"></span>
//               </div>
//             </div>
//           </form>
//         </div>
//         <div className="map-container">
//           <iframe
//             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.977022650861!2d77.41669532715294!3d28.510339025731756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce9739c3d44c7%3A0x691598ec71fe120c!2sCRC%20The%20Flagship!5e0!3m2!1sen!2sin!4v1693055910643!5m2!1sen!2sin"
//             width="100%"
//             height="450"
//             style={{ border: 0 }}
//             allowFullScreen=""
//             loading="lazy"
//             referrerPolicy="no-referrer-when-downgrade"
//             title="Google Maps - CRC The Flagship Location"
//           ></iframe>

//         </div>
//       </div>
//     </section>
//   );

// };

// export default ContactSection;
