import React from 'react';
import { Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faHome } from '@fortawesome/free-solid-svg-icons';
import OverviewGrid from './overviewGrid';
const Overview = () => {
    const listItemStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '10px',
        textAlign: 'justify'
    };

    const projectInfo = {
        overview: {
            title: "Overview",
            description: "M3M The Cullinan is a super-luxury residential project located in Sector 94, Noida, India, developed by M3M India, one of the leading real estate developers in the country. The project is named after the Cullinan Diamond, the largest rough diamond ever discovered. M3M The Cullinan offers a selection of 3, 4, and 5 BHK luxurious residences designed to offer the highest standards of luxury living. Key features of the apartments include spacious living and dining areas, high-end finishes and fittings, private balconies with stunning views of the city, master bedrooms with walk-in closets and en-suite bathrooms, and fully equipped kitchens with branded appliances."
        },
        keyFeatures: {
            title: "Key Features",
            features: [
                "Super-luxury residential project with 3, 4, and 5 BHK apartments.",
                "High-end finishes and fittings, including private balconies with stunning city views.",
                "World-class amenities, including a state-of-the-art fitness center, swimming pool, spa and wellness center, kids' play area, jogging track and garden, skating rink, flower pocket, and party area.",
                "Convenient location in Sector 94, Noida, close to all major social and civic infrastructure."
            ]
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <Divider textAlign='left'>
                <Typography variant="h4" gutterBottom align="center" style={{ marginBottom: '15px' }}>
                    {projectInfo.overview.title}
                </Typography>
            </Divider>
            <Typography variant="subtitle2" paragraph style={{ textAlign: 'justify' }}>
                {projectInfo.overview.description}
            </Typography>
            <OverviewGrid/>
            <Typography variant="h5" gutterBottom style={{ marginTop: '20px', textAlign: 'justify' }}>
                {projectInfo.keyFeatures.title}
            </Typography>
            <List>
                {projectInfo.keyFeatures.features.map((feature, index) => (
                    <ListItem key={index} style={listItemStyle}>
                        <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: '8px', marginTop: '3px' }} />
                        <ListItemText>
                            <Typography variant="subtitle2">
                                {feature}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default Overview;
