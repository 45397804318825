import React from 'react';
import Projects from './Projects';
import '../App.css';
import '../Styles/Projects.css';
import project1Image1 from '../assets/CRC-Flagship/1.jpg';
import project1Image2 from '../assets/CRC-Flagship/2.jpg';
import project1Image3 from '../assets/CRC-Flagship/3.jpg';
import project2Image1 from '../assets/CRC-Joyous/1.jpg';
import project2Image2 from '../assets/CRC-Joyous/2.jpg';
import project2Image3 from '../assets/CRC-Joyous/3.jpg';
import project3Image1 from '../assets/Godrej-Tropical/1.jpg';
import project3Image2 from '../assets/Godrej-Tropical/2.jpg';
import project3Image3 from '../assets/Godrej-Tropical/3.jpg';
import project4Image1 from '../assets/LT/1.jpg';
import project4Image2 from '../assets/LT/2.jpg';
import project4Image3 from '../assets/LT/3.jpg';
import project5Image1 from '../assets/max/1.jpg';
import project5Image2 from '../assets/max/2.jpg';
import project5Image3 from '../assets/max/3.jpg';
import project6Image1 from '../assets/M3M/1.jpg';
import project6Image2 from '../assets/M3M/2.jpg';
import project6Image3 from '../assets/M3M/3.jpg';

const ProjectsPage = () => {
  const projects = [
    {
      title: "CRC The Flagship",
      location: "Sector 140A – Noida",
      price: "18 lacs - onwards",
      priceCategory: "green",
      images: [project1Image1, project1Image2,project1Image3],
    },
    {
      title: "CRC Joyous",
      location: "Tech Zone 4 – Noida Ext",
      price: "75 lacs - onwards",
      priceCategory: "green",
      images: [project2Image1, project2Image2,project2Image3],
    }, {
      title: "Godrej Tropical Isle",
      location: "Sector 146 – Noida",
      price: "2.23 CR - onwards",
      priceCategory: "orange",
      images: [project3Image1, project3Image2,project3Image3],
    }, {
      title: "L&T",
      location: "Sector 128 – Noida",
      price: "for price call us",
      priceCategory: "orange",
      images: [project4Image1, project4Image2,project4Image3],
    },
    {
      title: "MAX",
      location: "Sector 128 – Noida",
      price: "7.50 cr - onwards",
      priceCategory: "red",
      images: [project5Image1, project5Image2,project5Image3],
    }, {
      title: "M3M The Cullinan",
      location: "Sector 94 – Noida",
      price: "5.99 cr - onwards",
      priceCategory: "red",
      images: [project6Image1, project6Image2,project6Image3],
    },
  ];

  const projectsPerPage = 3;

  return (
    <div id="projects-page">
      {/* <div id="our-services-section" className="section" style={{ paddingTop: '100px' }}> */}
        <Projects projects={projects} projectsPerPage={projectsPerPage} />

      </div>
  );
};

export default ProjectsPage;
