import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { css } from '@emotion/react';

import SyncLoader from 'react-spinners/SyncLoader';

const ApplyNow = ({ jobTitle }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
  const [houseNo, setHouseNo] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [street, setStreet] = useState('');
  const [stateProvince, setStateProvince] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [resume, setResume] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    houseNo: '',
    zipCode: '',
    street: '',
    stateProvince: '',
    city: '',
    country: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    if (
      firstName.trim() === '' ||
      lastName.trim() === '' ||
      email.trim() === '' ||
      mobileNumber.trim() === '' ||
      zipCode.trim() === '' ||
      street.trim() === '' ||
      stateProvince.trim() === '' ||
      city.trim() === '' ||
      country.trim() === ''
    ) {
      // Set error messages for the empty fields if needed
      setErrors({
        firstName: firstName.trim() === '' ? 'First name is required' : '',
        lastName: lastName.trim() === '' ? 'Last name is required' : '',
        email: email.trim() === '' ? 'Valid email is required' : '',
        mobileNumber: mobileNumber.trim() === '' ? 'Valid mobile number is required' : '',
        zipCode: zipCode.trim() === '' ? 'Zip/Postal code is required' : '',
        street: street.trim() === '' ? 'Street is required' : '',
        stateProvince: stateProvince.trim() === '' ? 'State/Province is required' : '',
        city: city.trim() === '' ? 'City is required' : '',
        country: country.trim() === '' ? 'Country is required' : '',
      });
      return;
    }

    setIsLoading(true);
    // Validation logic (excluding houseNo)
    const validationErrors = {};

    if (firstName.trim() === '') {
      validationErrors.firstName = 'First name is required';
    }

    if (lastName.trim() === '') {
      validationErrors.lastName = 'Last name is required';
    }

    if (email.trim() === '' || !isValidEmail(email)) {
      validationErrors.email = 'Valid email is required';
    }

    if (mobileNumber.trim() === '' || !isValidPhoneNumber(mobileNumber)) {
      validationErrors.mobileNumber = 'Valid mobile number is required';
    }

    if (zipCode.trim() === '') {
      validationErrors.zipCode = 'Zip/Postal code is required';
    }

    if (street.trim() === '') {
      validationErrors.street = 'Street is required';
    }

    if (stateProvince.trim() === '') {
      validationErrors.stateProvince = 'State/Province is required';
    }

    if (city.trim() === '') {
      validationErrors.city = 'City is required';
    }

    if (country.trim() === '') {
      validationErrors.country = 'Country is required';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Prepare the data to send using FormData
    const formData = new FormData();
    formData.append('jobTitle', jobTitle);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('mobileNumber', mobileNumber);
    formData.append('currentAddress', currentAddress);
    if (houseNo.trim() !== '') { // Only append houseNo if it's not empty
      formData.append('houseNo', houseNo);
    }
    formData.append('zipCode', zipCode);
    formData.append('street', street);
    formData.append('stateProvince', stateProvince);
    formData.append('city', city);
    formData.append('country', country);
    formData.append('resume', resume);

    try {
      // Send the data to the webhook URL using a POST request
      const response = await fetch('https://hook.eu1.make.com/dhsjdj1im2oxpevpid4d1a8vsccqm9yf', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        // Handle a successful webhook response here if needed
        console.log('Webhook success:', response.statusText);
      } else {
        // Handle errors if the webhook request fails
        console.error('Webhook failed:', response.statusText);
      }
    } catch (error) {
      console.error('Webhook error:', error);
    }
    setIsLoading(false);

    // Reset form fields and errors after successful submission
    setFirstName('');
    setLastName('');
    setEmail('');
    setMobileNumber('');
    setCurrentAddress('');
    setHouseNo('');
    setZipCode('');
    setStreet('');
    setStateProvince('');
    setCity('');
    setCountry('');
    setResume(null);
    setErrors({});
  };

  const isValidEmail = (email) => {
    // You can implement your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // You can implement your phone number validation logic here
    return /^[0-9]{10}$/.test(phoneNumber);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <motion.form
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            onSubmit={handleSubmit}
          >
            <div className="heading">
              <div className="pseudo" />
              <div className="text-wrapper">Application Form</div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-danger">{errors.firstName}</div>
                <div className="div-form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setErrors({ ...errors, firstName: '' });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-danger">{errors.lastName}</div>
                <div className="div-form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setErrors({ ...errors, lastName: '' });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-danger">{errors.email}</div>
                <div className="div-form-group-2">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({ ...errors, email: '' });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-danger">{errors.mobileNumber}</div>
                <div className="div-form-group-2">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Mobile Number"
                    value={mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                      setErrors({ ...errors, mobileNumber: '' });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-danger">{errors.houseNo}</div>
                <div className="div-form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="House No"
                    value={houseNo}
                    onChange={(e) => {
                      setHouseNo(e.target.value);
                      setErrors({ ...errors, houseNo: '' });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-danger">{errors.zipCode}</div>
                <div className="div-form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip/Postal Code"
                    value={zipCode}
                    onChange={(e) => {
                      setZipCode(e.target.value);
                      setErrors({ ...errors, zipCode: '' });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-danger">{errors.street}</div>
                <div className="div-form-group-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street"
                    value={street}
                    onChange={(e) => {
                      setStreet(e.target.value);
                      setErrors({ ...errors, street: '' });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-danger">{errors.stateProvince}</div>
                <div className="div-form-group-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State/Province"
                    value={stateProvince}
                    onChange={(e) => {
                      setStateProvince(e.target.value);
                      setErrors({ ...errors, stateProvince: '' });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-danger">{errors.city}</div>
                <div className="div-form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setErrors({ ...errors, city: '' });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-danger">{errors.country}</div>
                <div className="div-form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Country"
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                      setErrors({ ...errors, country: '' });
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Resume upload */}
            <div className="label-upload-resume">Upload Resume</div>
            <div className="row">
              <div className="col-md-12">
                <div className="input-2">
                  <div className="div-wrapper">
                    <input
                      type="file"
                      id="resume-upload"
                      className="form-control"
                      accept=".pdf,.doc,.docx"
                      onChange={(e) => setResume(e.target.files[0])}
                    />
                  </div>
                  <div className="text-wrapper-3">
                    {resume ? resume.name : 'No file chosen'}
                  </div>
                </div>
              </div>
            </div>
            {/* Submit button */}
            <div className="row">
              <div className="col-md-6">
                <button
                  type="submit"
                  className="careers-button button-text whatsapp-number-button"
                  disabled={isLoading}
                  style={{ border: '2px solid #fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', }} // Add inline style for the border
                >
                  {isLoading ? (
                    <SyncLoader color={'#000'} css={spinnerStyles} size={8} /> // Loading spinner
                  ) : (
                    <div className="text-wrapper-4">Submit</div>
                  )}
                </button>

              </div>
            </div>
          </motion.form>
          <Link to="/" className="mt-3">Back to Job Listings</Link>
        </div>
      </div>
    </div>
  );
};
const spinnerStyles = css`
  display: block;
  margin: 0 auto;
`;


export default ApplyNow;
