import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ProjectsSliderPages from './ProjectsSliderPages';
import Testimonials from './Testimonials';
// import Footer from './Footer';
import ImageSwiper from './ImageSwiper';
import TaglineComponent from './TaglineComponent';
// import Bottom from './bottom';
import About from './AboutUs';
import ContactSection from './ContactSection';
const App = () => {
    return (
        <div id="home-section">

            <TaglineComponent />
            <ImageSwiper />
            <div style={{ paddingTop: '3%', paddingBottom: '3%' }}>
                <ProjectsSliderPages />
            </div>
            <div className="horizontal-line"></div>
            <div id="testimonials-section" style={{ paddingTop: '2%', paddingBottom: '3%' }}>
                <Testimonials />
            </div>
            <div className="horizontal-line" ></div>
            <h2 style={{textAlign:'center', paddingTop: '1%'}}>Get in Touch</h2>
            <ContactSection />
            {/* <Bottom />

            <Footer /> */}          
        </div>
    );
};

export default App;
