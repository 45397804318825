import React from "react";
import { Typography, Grid  } from "@mui/material";

const OverviewGrid = () => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Address</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Plot No. 1B, Sector 140-A, NOIDA, Distt. Gautam Buddha Nagar, Uttar Pradesh
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Project Size</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              11 Acre
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Launch Date</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Feb 23
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Status</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Under Construction
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Price per Sqft</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              ₹8290 - 32250
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Possession by</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Feb 28
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Total Units</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              1000
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Total Towers</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              4
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Project Type</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Commercial
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Property Type</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              Office Space
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary">
              <strong>Pincode</strong>
            </Typography>
            <Typography variant="body2" color="text.primary">
              201305
            </Typography>
          </Grid>
        </Grid>
      );
};


export default OverviewGrid;
