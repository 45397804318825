import React from "react";
import SmallerLogo from '../assets/logo4x.png';
import ImagePuzzle from './ImagePuzzle';
import instagram from '../assets/instagram.png'
import { Container, Grid, Typography, Link, Box, IconButton } from "@mui/material";
import { Facebook, LinkedIn, YouTube, LocationOn, MailOutline, Phone } from "@mui/icons-material"; // Import MailOutline and Phone icons


const linkStyles = {
  textDecoration: "none",
  color: "#333",
  transition: "color 0.3s ease-in-out",
  "&:hover": {
    color: "#ff5733",
  },
};

const boxShadowStyles = {
  boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
};

const centerTextStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
};

const iconStyles = {
  marginRight: "2px",
};
const textSpacingStyles = {
  marginBottom: "5%",
};
const imageStyle = {
  width: '30%',
  marginBottom: '5px',
  filter: 'drop-shadow(5px 5px 10px rgba(0,0,0,0.35))'
};

const Bottom = () => {
  return (
    <Box bgcolor="#fdfdfd" py={4} sx={boxShadowStyles}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box textAlign="center">
            {/* <img
      src={SmallerLogo}
      alt="Company Logo"
      style={imageStyle}
    /> */}
    <ImagePuzzle />
              <Typography variant="h5" sx={{ fontFamily: '"Roboto-Regular", Helvetica', color: "#323334", fontStyle: "bold", fontSize: "25px" }}>Imaginative Shapes Pvt Ltd</Typography>
              {/* <Typography variant="h6" sx={{ marginTop: '1px', fontFamily: '"Roboto-Regular", Helvetica', color:"#323334" }}>Pvt Ltd</Typography> */}
              {/* <Link href="#" variant="body2" underline="none" sx={linkStyles}>
                READ MORE
              </Link> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <Typography variant="h6" style={textSpacingStyles}>Quick Links</Typography>
            <Typography variant="body2" color="textSecondary" style={textSpacingStyles}>
              <Link href="/" sx={linkStyles}>
                Home
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" style={textSpacingStyles}>
              <Link href="/services" sx={linkStyles}>
                Projects
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" style={textSpacingStyles}>
              <Link href="/blogs" sx={linkStyles}>
                Blogs
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" style={textSpacingStyles}>
              <Link href="/contact" sx={linkStyles}>
                Contact Us
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <Typography variant="h6" style={textSpacingStyles}>Support</Typography>
            <Typography variant="body2" color="textSecondary" style={textSpacingStyles}>
              <Link href="/events" sx={linkStyles}>
                Events
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" style={textSpacingStyles}>
              <Link href="/privacy-policy" sx={linkStyles}>
                Privacy Policy
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" style={textSpacingStyles}>
              <Link href="/terms-and-conditions" sx={linkStyles}>
                Terms and Conditions
              </Link>
            </Typography>

          </Grid>
          <Grid item xs={12} sm={3}>
            <Box style={centerTextStyles}>
              <Typography variant="h6" style={textSpacingStyles}>Contact Info</Typography>
              <Box display="flex" alignItems="center" style={textSpacingStyles}>

                <Typography variant="body2" color="textSecondary">
                  <LocationOn color="#ff0000" fontSize="small" style={{ color: "ff0000" }} />
                  Office NO . 304 , Tower T3 , Golden I , Techzone 4 Greater Noida West , Noida, Uttar Pardesh. 201009 - India
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" style={textSpacingStyles}>
                <MailOutline color="primary" fontSize="small" style={iconStyles} />
                <Link href="mailto:info@iconicstone.co.in" sx={linkStyles}>
                  info@iconicstone.co.in
                </Link>
              </Box>

              <Box display="flex" alignItems="center" style={textSpacingStyles}>
                <Phone color="#primary" fontSize="small" style={iconStyles} />
                <a href="tel:+919810282307" style={linkStyles}>
                  (+91) 9810282307
                </a>
              </Box>
              <Box mt={2}>
                <IconButton href="https://www.facebook.com/profile.php?id=61551717519756" target="_blank" style={{ color: "#1877f2" }}>
                  <Facebook />
                </IconButton>
                <IconButton href="https://www.linkedin.com/company/is-iconic-stone/" target="_blank" style={{ color: "#0077B5" }}>
                  <LinkedIn />
                </IconButton>
                <IconButton href="https://www.instagram.com/iconic.stone/" target="_blank" style={{ color: "#0077B5" }}>
                <img src={instagram} alt="Instagram" style={{ width: '20px' }}/>
                 </IconButton>
                <IconButton href="https://www.youtube.com/@IconicStone" target="_blank" style={{ color: "#ff0000" }}>
                  <YouTube />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Bottom;
