import React from 'react';
import CompanyVision from './CompanyVision';
import ColumnsAndRowsWithIcons from './ColumnsAndRowsWithIcons';
import { Player } from '@lottiefiles/react-lottie-player';
import image from './animation_lmnrduus.json';
import Grid from '@mui/material/Grid';
import { Container, Typography, Divider } from '@mui/material';
import { motion } from 'framer-motion'; // Import Framer Motion

const AboutPage = () => {
  const missionText = `To be the catalyst of positive change in the real estate landscape, setting new standards for innovation, sustainability, and inclusivity.`;

  const textVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  return (
    <Container sx={{ py: 4 }}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom sx={{ marginTop: '10px', fontFamily: '"Roboto-Regular", Helvetica', color: "#323334" }}>
          Our Vision
        </Typography>
        <Divider sx={{ marginBottom: '20px' }} />
        
      </div>
      <Grid container spacing={5} style={{ marginTop: '50px', marginBottom: '50px' }} >
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Player
            autoplay
            loop
            src={image}
            style={{ height: '100%', width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{ xs: 1, md: 2 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center horizontally
            textAlign: 'center', // Center text within the div
          }}
        >
          <CompanyVision />
          
        </Grid>

      </Grid>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom sx={{ marginTop: '10px', fontFamily: '"Roboto-Regular", Helvetica', color: "#323334" }}>
          Our Mission
        </Typography>
        <Divider sx={{ marginBottom: '20px' }} />
      </div>
      <ColumnsAndRowsWithIcons />
    </Container>
  );
};

export default AboutPage;



// ----------------------------- Old Code -----------------------------


// import React from 'react';
// import CompanyVision from './CompanyVision';
// import ColumnsAndRowsWithIcons from './ColumnsAndRowsWithIcons';
// import { Player } from '@lottiefiles/react-lottie-player';
// import image from './animation_lmnrduus.json';
// import Grid from '@mui/material/Grid';
// import { Container, Typography, Divider } from '@mui/material';
// import { motion } from 'framer-motion'; // Import Framer Motion

// const AboutPage = () => {
//   const missionText = `To be the catalyst of positive change in the real estate landscape, setting new standards for innovation, sustainability, and inclusivity.`;

//   const textVariants = {
//     hidden: { opacity: 0, scale: 0.9 },
//     visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
//   };

//   return (
//     <Container sx={{ py: 4 }}>
//       <div style={{ textAlign: 'center' }}>
//         <Typography variant="h3" gutterBottom sx={{ marginTop: '10px', fontFamily: '"Roboto-Regular", Helvetica', color: "#323334" }}>
//           Our Vision
//         </Typography>
//         <Divider sx={{ marginBottom: '20px' }} />
//         <CompanyVision />
//       </div>
//       <Grid container spacing={5} style={{ marginTop: '50px', marginBottom: '50px' }}>
//         <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
//           <Player
//             autoplay
//             loop
//             src={image}
//             style={{ height: '100%', width: '100%' }}
//           />
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={6}
//           order={{ xs: 1, md: 2 }}
//           style={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center', // Center horizontally
//             textAlign: 'center', // Center text within the div
//           }}
//         >
//           <motion.div
//             initial="hidden"
//             animate="visible"
//             variants={textVariants}
//             style={{ width: '100%' }}
//           >
//             <Typography variant="body1">
//               {missionText.split(' ').map((word, index) => (
//                 <motion.span
//                   key={index}
//                   initial={{ opacity: 0.7, scale: 0.9 }}
//                   whileHover={{ opacity: 1, scale: 1 }}
//                   transition={{ duration: 0.3, ease: 'easeInOut' }}
//                 >
//                   {word}{' '}
//                 </motion.span>
//               ))}
//             </Typography>
//           </motion.div>
//         </Grid>

//       </Grid>
//       <div style={{ textAlign: 'center' }}>
//         <Typography variant="h3" gutterBottom sx={{ marginTop: '10px', fontFamily: '"Roboto-Regular", Helvetica', color: "#323334" }}>
//           Our Mission
//         </Typography>
//         <Divider sx={{ marginBottom: '20px' }} />
//       </div>
//       <ColumnsAndRowsWithIcons />
//     </Container>
//   );
// };

// export default AboutPage;
