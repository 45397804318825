import React, { useEffect, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Styles/ProjectSlide.css';
import '../Styles/ProjectsButton.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProjectSlide = ({ projects }) => {
  useEffect(() => {
    // You can perform any initial setup or data fetching here if needed.
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const outerSliderSettings = {
    infinite: true,
    speed: 800,
    slidesToShow: 3, // Show 3 project cards by default
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <FontAwesomeIcon icon={faAngleLeft} />, // Left arrow icon
    nextArrow: <FontAwesomeIcon icon={faAngleRight} />, // Right arrow icon
    responsive: [
      {
        breakpoint: 992, // Medium devices (tablets, smaller desktops)
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Small devices (landscape phones)
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576, // Extra small devices (portrait phones)
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const innerSliderSettings = {
    infinite: true,
    speed: 800,
    slidesToShow: 1, // Show 1 image at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: (current) => setCurrentImageIndex(current),
  };

  return (
    <div className="container">
      <h2 className="text-center mb-4">Our Projects</h2>
      <Slider {...outerSliderSettings}>
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <div className="card">
              <div className="project-image">
                <Slider {...innerSliderSettings} initialSlide={currentImageIndex}>
                  {project.images.map((image, imageIndex) => (
                    <div key={imageIndex}>
                      <img
                        src={image}
                        className="card-img-top"
                        alt={`${project.title} - Image ${imageIndex + 1}`}
                      />
                    </div>
                  ))}
                </Slider>
                <div className="image-overlay">
                  <Link to={`/project/${index}`} target="_blank" rel="noopener noreferrer">
                    <button className="detail-button">Show details</button>
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <h5 className="card-location" style={{ alignItems: 'center', textAlign: 'center' }}>
                  <Player
                    autoplay
                    loop
                    src="https://lottie.host/d49a09cb-f156-4ab4-9825-3222ee3bdd08/92UAhBG5tv.json"
                    style={{ height: '40px', width: '40px', marginTop: '-8px' }}
                  />{' '}
                  {project.location}
                </h5>
                <hr className="horizontal-line1" />
                <div className="card-content">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Player
                      autoplay
                      loop
                      src="https://lottie.host/f1a204d4-d74f-4c3f-940a-b1f6b3a5387b/tzZYKaaxUf.json"
                      style={{ height: '50px', width: '50px', marginRight: '10px', marginTop: '-8px' }}
                    />
                    <span className={`card-price-${project.priceCategory}`}>{project.price}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProjectSlide;
