import React from 'react';
import { Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import OverviewGrid from './overviewGrid';
const Overview = () => {
    const listItemStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '10px',
        textAlign: 'justify'
    };

    const projectInfo = {
        overview: {
            title: "Overview",
            description: "CRC Joyous is a meticulously planned residential development located at Techzone IV, Greater Noida, Uttar Pradesh. Developed by CRC Group, a renowned real estate brand in Noida, this project spans over 4 acres of land and offers 536 units of luxurious and Vastu-compliant 2 BHK, 3 BHK & 4 BHK apartments. The apartments boast carpet areas ranging between 50.07 square meters and 189.10 square meters. Additionally, the project includes 9 units of retail shops. Designed by the acclaimed architect Hafeez Contractor and constructed using advanced MIVAN technology, each apartment in CRC Joyous offers serene views, facing either a green area or a road. The project enjoys a superior location with a proposed metro station just 100 meters away, providing excellent connectivity. It is also conveniently located near Gaur Mall, hospitals, and 12 schools within a 2 km radius."
        },
        highlights: {
            title: "Highlights of CRC Joyous",
            items: [
                "Superior location with a proposed metro station at a 100-meter distance.",
                "Exclusive clubhouse spanning 23,000 sq ft with ultra-luxury amenities.",
                "Close proximity to Gaur Mall, hospitals, and 12 schools within a 2 km radius.",
                "Designed by renowned architect Hafeez Contractor and constructed using MIVAN technology.",
                "Each apartment faces either a green area or a road.",
                "Wide range of amenities to suit the needs of all residents."
            ]
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <Divider textAlign='left'>
                <Typography variant="h4" gutterBottom align="center" style={{ marginBottom: '15px' }}>
                    {projectInfo.overview.title}
                </Typography>
            </Divider>
            <Typography variant="subtitle2" paragraph style={{ textAlign: 'justify' }}>
                {projectInfo.overview.description}
            </Typography>
            <OverviewGrid/>
            <Typography variant="h5" gutterBottom style={{ marginTop: '20px', textAlign: 'justify' }}>
                {projectInfo.highlights.title}
            </Typography>
            
            <List>
                {projectInfo.highlights.items.map((item, index) => (
                    <ListItem key={index} style={listItemStyle}>
                        <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: '8px', marginTop: '3px' }} />
                        <ListItemText>
                            <Typography variant="subtitle2">
                                {item}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default Overview;
