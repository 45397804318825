import React, { useState, useEffect, useRef } from 'react';
import Divider from '@mui/material/Divider';
import '../Styles/Testimonials.css'; // Your existing CSS for styling

const TestimonialSlider = ({ testimonials }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const shortMessage = useRef('');
  const isMouseHoveringOnCard = useRef(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isMouseHoveringOnCard.current) {
        setCurrentSlideIndex((prevSlideIndex) =>
          prevSlideIndex === testimonials.length - 1 ? 0 : prevSlideIndex + 1
        );
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [testimonials]);

  const handlePreviousSlide = () => {
    setCurrentSlideIndex((prevSlideIndex) =>
      prevSlideIndex === 0 ? testimonials.length - 1 : prevSlideIndex - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentSlideIndex((prevSlideIndex) =>
      prevSlideIndex === testimonials.length - 1 ? 0 : prevSlideIndex + 1
    );
  };

  const handleMouseEnterCard = () => {
    isMouseHoveringOnCard.current = true;
  };

  const handleMouseLeaveCard = () => {
    isMouseHoveringOnCard.current = false;
  };

  const { name, role, message, image } = testimonials[currentSlideIndex];

  shortMessage.current = message.split(' ').slice(0, 10).join(' ');
  const isMessageTruncated = message.split(' ').length > 10;

  return (
    <div className="testimonials-container">
      <div style={{ paddingBottom: '1.5%' }}>
        <h2 className="text-center mb-4">Testimonials</h2>
      </div>
      <div className="testimonial-slider">
        <button
          className="test-slider-button test-left-button"
          onClick={handlePreviousSlide}
        >
          &lt;
        </button>
        <div
          className="testimonial"
          onMouseEnter={handleMouseEnterCard}
          onMouseLeave={handleMouseLeaveCard}
        >
          <img
            src={image}
            alt={name}
            className="rounded-circle shadow-1-strong mb-4"
            style={{ width: '150px' }}
          />
          <div className="testimonial-details">
            <h6 className="testimonial-name">
              <strong>{name}</strong>
            </h6>
            <h5 className="testimonial-role">{role}</h5>
          </div>
          <div
            className={`testimonial-message-start ${
              isMessageTruncated ? 'truncated' : ''
            }`}
          >
            <Divider variant="middle" className="divider" />
            {`"${shortMessage.current}...`}
          </div>
          <div className="testimonial-message testimonial-message-hidden">
            <Divider variant="middle" className="divider" />
            {`"${message}"`}
          </div>
        </div>
        <button
          className="test-slider-button test-right-button"
          onClick={handleNextSlide}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default TestimonialSlider;
