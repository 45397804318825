import React, { useState, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; // Import Slider from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Styles/Projects.css';
import '../Styles/ProjectsButton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const Projects = ({ projectsPerPage, projects }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [showMoreButton, setShowMoreButton] = useState(true);

  const itemsPerPage = projectsPerPage || 3;
  const totalPages = Math.ceil(projects.length / itemsPerPage);

  const loadMoreProjects = () => {
    const nextPage = currentPage + 1;

    if (nextPage <= totalPages) {
      const indexOfLastProject = nextPage * itemsPerPage;
      const newDisplayedProjects = projects.slice(0, indexOfLastProject);
      setDisplayedProjects(newDisplayedProjects);
      setCurrentPage(nextPage);
    } else {
      setShowMoreButton(false);
    }
  };

  const hideProjects = () => {
    setDisplayedProjects(projects.slice(0, itemsPerPage));
    setCurrentPage(1);
    setShowMoreButton(true);
  };

  useEffect(() => {
    setDisplayedProjects(projects.slice(0, itemsPerPage));
    setCurrentPage(1);
    setShowMoreButton(true);
  }, [projects]);

  const sliderSettings = {

    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <div className="container">
      <h2 className="text-center mb-4">Our Projects</h2>
      <div className="row">
        {displayedProjects.map((project, index) => (
          <div key={index} className="col-lg-4 col-md-6 mb-4">
            <div className="card">
              <div className="project-image-unique">
                <Slider {...sliderSettings}>
                  {project.images.map((image, imageIndex) => (
                    <img
                      key={imageIndex}
                      src={image}
                      className="card-img-top"
                      alt={`${project.title} - Image ${imageIndex + 1}`}
                    />
                  ))}
                </Slider>
                <div className="image-overlay">
                <Link to={`/project/${index}`} target="_blank" rel="noopener noreferrer">
                    <button className="detail-button">Show details</button>
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <h5 className="card-location" style={{ alignItems: 'center' ,textAlign:'center'}}>
                <Player
                      autoplay
                      loop
                      src="https://lottie.host/d49a09cb-f156-4ab4-9825-3222ee3bdd08/92UAhBG5tv.json"
                      style={{ height: '40px', width: '40px' ,marginTop: '-8px'}} // Adjust margins as needed
                    /> {project.location}
                </h5>
                <hr className="horizontal-line1" />
                <div className="card-content">
                  <div style={{ display: 'flex', alignItems: 'center'}}>
                    <Player
                      autoplay
                      loop
                      src="https://lottie.host/f1a204d4-d74f-4c3f-940a-b1f6b3a5387b/tzZYKaaxUf.json"
                      style={{ height: '50px', width: '50px', marginRight: '10px' ,marginTop: '-8px'}} // Adjust margins as needed
                    />
                    {/* https://lottie.host/774d8363-18c8-4ea4-91e3-249ccdfeeb6a/xBgQLT7goV.json
                    https://lottie.host/5325c66a-0f35-4d5d-8125-4c0f450f42df/oRhC6gCYI2.json */}
                    <span className={`card-price-${project.priceCategory}`}>
                      {project.price}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-4">
        {showMoreButton ? (
          <div className="Projects-slider">
            <button className="slider-button left-button projects-button-primary" onClick={loadMoreProjects}>
              <FontAwesomeIcon icon={faAngleUp} />
            </button>
            <button className="slider-button right-button projects-button-secondary" onClick={hideProjects}>
              <FontAwesomeIcon icon={faAngleDown} />
            </button>
          </div>
        ) : (
          <button className="projects-button projects-button-secondary ml-2" onClick={hideProjects}>
            <FontAwesomeIcon icon={faAngleUp} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Projects;
