import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Styles/ImageSwiper.css';
import image3 from '../assets/swiper/1.jpg';
import image1 from '../assets/swiper/2.jpg';
import image2 from '../assets/swiper/3.jpg';
import image4 from '../assets/swiper/4.jpg';
const images = [
  image1,
  image2,
  image3,
  image4
  // Add more image URLs here
];

const ImageSwiper = () => {
  console.log("ImageSwiper rendered"); // Debugging
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false
  };

  return (
    <div className="ForSize">
    <div className="ImageSwiper">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slide">
            <img src={image} alt={`Slide ${index}`} className="swiper-img" />
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

export default ImageSwiper;
